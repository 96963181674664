export const packList = [
"angel.json",
"angel_encounter.json",
"ant.json",
"ant_encounter.json",
"aoa.json",
"aoa_encounter.json",
"bkw.json",
"bkw_encounter.json",
"cap.json",
"cap_encounter.json",
"core.json",
"core_encounter.json",
"cyclops.json",
"cyclops_encounter.json",
"deadpool.json",
"deadpool_encounter.json",
"drax.json",
"drax_encounter.json",
"drs.json",
"drs_encounter.json",
"gam.json",
"gam_encounter.json",
"gambit.json",
"gambit_encounter.json",
"gmw.json",
"gmw_encounter.json",
"gob_encounter.json",
"hlk.json",
"hlk_encounter.json",
"hood_encounter.json",
"iceman.json",
"iceman_encounter.json",
"ironheart.json",
"ironheart_encounter.json",
"jubilee.json",
"jubilee_encounter.json",
"mojo_encounter.json",
"msm.json",
"msm_encounter.json",
"mts.json",
"mts_encounter.json",
"mut_gen.json",
"mut_gen_encounter.json",
"nebu.json",
"nebu_encounter.json",
"next_evol.json",
"next_evol_encounter.json",
"nova.json",
"nova_encounter.json",
"phoenix.json",
"phoenix_encounter.json",
"psylocke.json",
"psylocke_encounter.json",
"qsv.json",
"qsv_encounter.json",
"rogue.json",
"rogue_encounter.json",
"ron_encounter.json",
"scw.json",
"scw_encounter.json",
"sm.json",
"sm_encounter.json",
"spdr.json",
"spdr_encounter.json",
"spiderham.json",
"spiderham_encounter.json",
"stld.json",
"stld_encounter.json",
"storm.json",
"storm_encounter.json",
"thor.json",
"thor_encounter.json",
"toafk_encounter.json",
"trors.json",
"trors_encounter.json",
"twc_encounter.json",
"valk.json",
"valk_encounter.json",
"vision.json",
"vision_encounter.json",
"vnm.json",
"vnm_encounter.json",
"warm.json",
"warm_encounter.json",
"wolv.json",
"wolv_encounter.json",
"wsp.json",
"wsp_encounter.json",
"x23.json",
"x23_encounter.json",
];