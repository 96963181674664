import ScarletWitch from "./scarlet-witch";
import GalaxiesMostWanted from "./galaxies-most-wanted";
import KitchenSink from "./kitchensink";
import MadTitansShadow from "./mad-titans-shadow";

// const MISSING_CARD_IMAGE_MAP = {
//   ...ScarletWitch,
//   ...GalaxiesMostWanted,
//   ...MadTitansShadow,
//   ...KitchenSink,
// };

const MISSING_CARD_IMAGE_MAP = {};

export default MISSING_CARD_IMAGE_MAP;
