export const packList = [
"aspect.json",
"challenge.json",
"location.json",
"malady.json",
"mission.json",
"reference_card.json",
"reward.json",
"weather.json",
"path/branch.json",
"path/fractured_wall.json",
"path/general.json",
"path/grassland.json",
"path/lakeshore.json",
"path/lone_tree_station.json",
"path/marsh_of_rebirth.json",
"path/meadow.json",
"path/mountain_pass.json",
"path/northern_outpost.json",
"path/old_growth.json",
"path/ravine.json",
"path/river.json",
"path/spire.json",
"path/swamp.json",
"path/the_valley.json",
"path/tumbledown.json",
"path/white_sky.json",
"path/woods.json",
"ranger/artificer.json",
"ranger/artisan.json",
"ranger/conciliator.json",
"ranger/explorer.json",
"ranger/forager.json",
"ranger/personalities.json",
"ranger/shaper.json",
"ranger/shepherd.json",
"ranger/traveler.json",
];