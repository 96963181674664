export const packList = [
"marvel_legendary_Annihilation.json",
"marvel_legendary_AntMan.json",
"marvel_legendary_BlackPanther.json",
"marvel_legendary_BlackWidow.json",
"marvel_legendary_CaptainAmerica.json",
"marvel_legendary_Champions.json",
"marvel_legendary_CivilWar.json",
"marvel_legendary_CoreSet.json",
"marvel_legendary_DarkCity.json",
"marvel_legendary_Deadpool.json",
"marvel_legendary_Dimensions.json",
"marvel_legendary_DoctorStrange.json",
"marvel_legendary_FantasticFour.json",
"marvel_legendary_FearItself.json",
"marvel_legendary_GeneralCards.json",
"marvel_legendary_GuardiansOfTheGalaxy.json",
"marvel_legendary_HeroesOfAsgard.json",
"marvel_legendary_IntoTheCosmos.json",
"marvel_legendary_MSGuardiansOfTheGalaxy.json",
"marvel_legendary_MSInfinitySaga.json",
"marvel_legendary_MarvelStudios.json",
"marvel_legendary_MessiahComplex.json",
"marvel_legendary_MidnightSuns.json",
"marvel_legendary_NewMutants.json",
"marvel_legendary_Noir.json",
"marvel_legendary_PaintTheTownRed.json",
"marvel_legendary_RealmOfKings.json",
"marvel_legendary_Revelations.json",
"marvel_legendary_SecretWars1.json",
"marvel_legendary_SecretWars2.json",
"marvel_legendary_Shield.json",
"marvel_legendary_SpiderManHomecoming.json",
"marvel_legendary_ThreeDimension.json",
"marvel_legendary_Venom.json",
"marvel_legendary_Villains.json",
"marvel_legendary_WorldWarHulk.json",
"marvel_legendary_XMen.json",
];